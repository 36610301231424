import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { List } from '@shared/types';
import { SnapScrollerList } from '../../../../components/SnapScroller';
import { ListCard } from '../../../../components/ListCard';
import { PublicEducator } from '@cademy-package/sdk-component-types';

type BaseCollectionsProps = {
    lists: List[];
    onClick: (listId: List['_id']) => void;
};

type ExtendedCollectionProps = BaseCollectionsProps & {
    educator: PublicEducator;
};

const CollectionsCarousel: FunctionComponent<BaseCollectionsProps> = ({ lists, onClick }) => {
    return (
        <SnapScrollerList className={styles.collectionsSnapScroller}>
            {[...lists].map((list) => {
                return (
                    <ListCard
                        key={list._id}
                        list={list}
                        role="button"
                        onClick={() => onClick(list._id)}
                    />
                );
            })}
        </SnapScrollerList>
    );
};

const CollectionsGrid: FunctionComponent<BaseCollectionsProps> = ({ lists, onClick }) => {
    return (
        <div className={styles.collectionsGrid}>
            {[...lists].map((list) => {
                return (
                    <ListCard
                        key={list._id}
                        list={list}
                        role="button"
                        onClick={() => onClick(list._id)}
                        preset="grid"
                    />
                );
            })}
        </div>
    );
};

const Collections: FunctionComponent<ExtendedCollectionProps> = ({ lists, educator, onClick }) => {
    if (lists.length < 5 || educator.profile_page_options?.collections_layout === 'grid') {
        return <CollectionsGrid lists={lists} onClick={onClick} />;
    }
    return <CollectionsCarousel lists={lists} onClick={onClick} />;
};

export const CourseCollections: FunctionComponent<ExtendedCollectionProps> = ({
    lists,
    educator,
    onClick,
}) => {
    if (lists.length === 0) return null;

    return (
        <>
            <div className={styles.collections}>
                <h3>Collections</h3>
                <Collections lists={lists} educator={educator} onClick={onClick} />
            </div>
            <h3>All Courses</h3>
        </>
    );
};
