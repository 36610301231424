import { Analytics, JestMock } from '@shared/analytics';
import { useContext, useMemo } from 'react';
import { AppContext } from '../../../contexts/AppContext';

const BASE_URL = process.env.NEXT_PUBLIC_CADEMY_API_URL || 'https://api.cademy.co.uk';

export const useAnalytics = () => {
    const appContext = useContext(AppContext);
    const recordAgainst = useMemo(() => {
        switch (appContext.context) {
            case 'marketplace':
                return 'marketplace';
            case 'minisite':
                return 'minisite';
            case 'embed':
                return 'embed';
        }
    }, [appContext]);

    return useMemo(() => {
        if (process.env.NODE_ENV === 'test') {
            return new JestMock();
        }

        return new Analytics({
            baseUrl: new URL(BASE_URL),
            segmentApiKey: process.env.NEXT_PUBLIC_SEGMENT_API_KEY,
            recordAgainst: recordAgainst,
        });
    }, [recordAgainst]);
};
